
import { IonPage, IonContent, IonList, IonItem, IonBackButton, IonToolbar, loadingController, IonSpinner, IonInfiniteScroll, IonInfiniteScrollContent } from '@ionic/vue';
import { defineComponent, onMounted, reactive } from 'vue';
import { useRouter } from 'vue-router';
import Empty from '../../../../components/Empty.vue';

/**
 * services
 */
import services from "../../../../services";

export default defineComponent({
  components: { IonPage, IonContent, IonList, IonItem, IonBackButton, IonToolbar, IonSpinner, Empty, IonInfiniteScroll, IonInfiniteScrollContent },
  props: {
    // postId
    id: {
      type: [Number],
      default: () => null,
    },
  },
  setup(props){
    const router = useRouter();
    const state = reactive({
      like:{
        loading: true,
        items: [{}],
        totalItems: 0,
        totalPages: 0,
        size: 20,
        page: 1
      },
      contentLoading: true,
    });

    const fetch = async (event: any = null, loadMore = false) => {
      state.like.loading = true;
      const params = {
          size: state.like.size,
          page: state.like.page
        }
      await services.likePostFindone(props.id, params).then(
         response => {
            const { data } = response;
            data.items.some((item: object) => {
              state.like.items.push(item);
            });
            state.like.totalItems = data.totalItems;
            state.like.totalPages = data.totalPages;
            state.like.loading = false;
            state.like.page++;
            if ( event ) {
              if ( data.items.length == 0 && loadMore ) {
                event.target.disabled = true;
              }
            }
         },
         error => {
          let message = '';
          try {
            message = error.response.data.message;
          } catch (e) {
            message = '';
            console.log(e);
          }
          console.log(message);
          if ( event ) {
            event.target.disabled = true;
          }
          state.like.loading = false;
          state.like.totalItems = 0;
        }
      );
      setTimeout(()=>{
        if ( event ) {
          event.target.complete();
        }

        state.contentLoading = false
      } ,500)
    }

    const onFollow = async (item: any) => {
      const loading = await loadingController
        .create({
          cssClass: 'my-custom-class',
          message: '잠시 기다려주세요.',
      });
      await loading.present();

      const params = {
        userId: item.user.id
      };
      if(item.user.follow == 0 ) {
        services.followAdd(params).then(
          response => {
            if(response.status == 200){
              item.user.follow = 1;
            }
            loading.dismiss();
          },
          error => {
            console.log(error);
            loading.dismiss();
          }
        )
      }else{
        console.log(item.user.follow)
        services.followRemove(params).then(
          response => {
            if(response.status == 204){
              item.user.follow = 0;
            }
            loading.dismiss();
          },
          error => {
            console.log(error);
            loading.dismiss();
          }
        )
      }
    }

    const onLoadData = (event: any) => {
      setTimeout(() => {
        fetch(event, true);
      }, 500)
    };


    const onUserLink = (id: number) => {
      /*
      router.push({
        name: "UserId",
        params: {id: id}
      })
      */
    }

    onMounted(() => {
      state.like.items = [];
      fetch();
      console.log('onMounted');
    })

    return{state, onUserLink, onFollow, onLoadData}
  }
})
